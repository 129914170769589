class Toggle {
  constructor(ref) {
    $(ref).on('click', (e) => {
      e.preventDefault()
      let open = false
      let container = $(ref).closest('.j-toggle')
      if ($(container).hasClass('open')){
         open = true
      }
      let list = $(container).siblings('.j-toggle')
      $(list).removeClass('open')
      if (open) {
        $(container).removeClass('open')
      }
      else $(container).addClass('open')

    })
  }
}
