// class Scroller {
//   constructor(ref) {
//     var fig = ref;
//     let b = $('body')
//     let w = $(window)
//     let speed = b.attr('data-speed')
//     w.on('scroll', () => this.scrolling(w,b,speed) )


//   }
// 		scrolling(ref, w,b, speed){
// 			let win_scroll = b.scrollTop()
// 			console.log(win_scroll)
// 		}
// }

