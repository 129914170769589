class TextAnimation {
  constructor(ref) {
    let w = $(window)
    let carousel = false
    if ($(ref).hasClass('.j-animateText')){
      carousel = true
    }
    let tlVision = new TimelineLite({ paused: true});
    let type = ($(ref).attr('data-word')) ? 'words' : 'lines'
    let visionIntroText = new SplitText(ref, {type:type});
    if (type == 'words') {
      var visionIntroLines = visionIntroText.words;
    }
    else{
      var visionIntroLines = visionIntroText.lines;

    }
    for(let i = 0; i<visionIntroLines.length; i++){
      visionIntroLines[i].innerHTML = '<span>'+visionIntroLines[i].innerHTML+'</span>';
    }
    $(visionIntroLines).find('> span').css('transition', 'transform 1300ms cubic-bezier(.19,1,.22,1) 100ms')
    $(visionIntroLines).find('> span').css('display', 'block')
    if (carousel) {
      tlVision.staggerFrom($(visionIntroLines).find('> span'), 0.2, {y:"100%", ease: Sine.easeOut}, "-=1");
    }
    else{
      tlVision.staggerFrom($(visionIntroLines).find('> span'), 0.5, {y:"100%", ease: Power3.easeOut},  0.08, "-=1");
    }
    $(ref).find('> div').css('overflow', 'hidden')

    w.on('scroll', () => this.funScroll(tlVision, ref) )
    this.funScroll(tlVision, ref)
  }

  funScroll(tlVision, ref){
    if($(ref).hasClass('j-animateText')){
      let isActive = $(ref).closest('.j-carouselSlide').hasClass('swiper-slide-active')
      Array.from($(ref).find('span')).forEach(v => {
      if (($(v).isOnScreen()) && (isActive) ){
        tlVision.play().timeScale(1);
        }
      });
    }
    else{
      Array.from($(ref).find('span')).forEach(v => {
      if ($(v).isOnScreen() ){
        tlVision.play().timeScale(1);
          }
        });
    }
    // Array.from($(ref).find('span')).forEach(v => {
    //   if ($(v).isOnScreen() ){
    //     tlVision.play().timeScale(1);
    //       }
    //     });


  }
  secondScroll(ref){
    let tlVision = new TimelineLite({ paused: true});
    let isActive = $(ref).closest('.j-carouselSlide').hasClass('swiper-slide-active')
    Array.from($(ref).find('span')).forEach(v => {
    if ($(v).isOnScreen() ){
      $(v).css('transform', 'translateY(0)')
      }
    });
  }
}

