
class NoooImgWrapper {
    constructor(ref, imgMaxWidth) {
        this.img = $(ref).find('img');
        this.src = this.img.attr('src');
        this.srcset = this.img.attr('srcset');
        this.srcsetList = '';
        this.srcsetTree =  [];
        this.maxAvailableSize = '';
        this.bgOverlay = $(ref).find('.bg-overlay');
        this.imgMaxWidth = ( imgMaxWidth !== undefined ? imgMaxWidth : 1800 );

        if(this.srcset !== false && typeof this.srcset !== 'undefined' && '' !== this.srcset) {
            this.srcsetTree = this.createSrcsetTree( this.srcset );
            this.maxAvailableSize = this.getMaxSize(this.img.attr('sizes'));

            // Changes this.src to best pick ( if it isn't the best pick already )
            this.pickBestSrcset(this.srcsetTree, this.maxAvailableSize);
        }

        if(this.bgOverlay.length){

            this.bgOverlay.css({
                backgroundImage : 'url('+this.src+')'
            })

        }else{

            $('<div class="bg-overlay" style="background-image: url('+this.src+');">').appendTo($(ref));

        }
    }


    /**
     *
     * Given a srcset string it maps it to an array of objects with all possible srcset urls
     *
     * @param srcset
     * @returns {Array}
     */

    createSrcsetTree(srcset){
        let array = [];
        let srcsetList = srcset.split(',');

        for (let i = 0; i < srcsetList.length; ++i) {

            let srcSetValues = srcsetList[i].trim().split(' ');
            let singleSrcSet = {
                url     : srcSetValues[0],
                width   : parseInt(srcSetValues[1].replace('w', '')),
            };

            array.push(singleSrcSet);
        }

        return array;
    }


    /**
     *
     * Given the content of attribute "sizes" it finds the max available size for an image
     *
     * @param imgsizes
     * @returns {number}
     */

    getMaxSize(imgsizes){
        let reversed = imgsizes.split(' ').reverse();
        let last = reversed[0];
        let cleanedVal = last.replace('px', '');
        return Number(cleanedVal);
    }


    /**
     *
     * Sets the right src for the current viewport
     *
     * @param srcsetTree
     * @param maxAvailableSize
     */

    pickBestSrcset(srcsetTree, maxAvailableSize) {

        let wWidth = $(window).width();

        for (let i = 0; i < srcsetTree.length; ++i) {

            if (srcsetTree[i].width > wWidth) {

                return srcsetTree[i].url;

            }

            if (i + 1 == srcsetTree.length) {

                window.console.log(this.imgMaxWidth);

                if ( maxAvailableSize < wWidth && srcsetTree[i].width < this.imgMaxWidth ) {

                    return;

                } else {

                    this.src = srcsetTree[i].url;

                }
            }
        }
    }

}

