class Overlay {
  constructor(ref) {
    let nameOverlay = $(ref).attr('data-overlay')
    let overlayContainer = $('.j-overlay')
    let b = $('body')
    let html = $('html')
    $(ref).on('click', (e) => {
      e.preventDefault()
      overlayContainer.addClass('open')
      b.addClass('overlayOpen')
      html.addClass('overlayOpen')
      let overlayContent = overlayContainer.find("[data-overlay='" + nameOverlay + "']")
      overlayContent.addClass('current')
      setTimeout(() => {
        this.initOverlay(overlayContainer, b, html, overlayContent)
      }, 100);
    }
    )
  }

  initOverlay(overlayContainer, b, html, overlayContent){
    // setTimeout(() => {
    //   let input = $(overlayContent).find('.search__input')
    //     $(input).focus()
    //   }, 300);

    let closeOverlay = $('.j-overlayClose')

    let selectSizeBtn = $(overlayContent).find('.j-selectSize')

    if (selectSizeBtn.length > 0) {
      this.initSelectSizeBtn(overlayContent)
    };

    Array.from(document.getElementsByClassName('j-color')).forEach(v => {
      new Color(v);
    });


    $(closeOverlay).on('click', (e) => {
      e.preventDefault()
      overlayContainer.removeClass('open')
      b.removeClass('overlayOpen')
      html.removeClass('overlayOpen')
      overlayContent.removeClass('current')
     }
    )
  }

  initSelectSizeBtn(overlayContent){
     Array.from(document.getElementsByClassName('j-selectSize')).forEach(v => {
    let closeOverlay = $('.j-overlayClose')
      $(v).on('click', (e)=>{
        e.preventDefault()
        $('.j-selectSize').removeClass('active')
        let btn = e.currentTarget
        $(btn).toggleClass('active')
        let text = $(btn).text()
        $('.j-selectSizeBtn span').text(text)
        setTimeout(() => {
          $(closeOverlay).trigger('click')

        }, 200);
      })
    });
  }
}

