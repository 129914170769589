class AnimationInOut {
  constructor(ref) {
    var w = $(window)
    w.on('scroll', () => this.funScroll(ref) )
    this.funScroll(ref)

  }

  funScroll(ref){

    Array.from($(ref).find('.js-in-out-item')).forEach(v => {
      let splitText = $(v).hasClass('j-textAnimation') ? true : false
      let delay = $(v).attr('data-delay') ? $(v).attr('data-delay') : 0
      let offset = $(v).attr('data-offset') ? parseInt($(v).attr('data-offset')) : 0
      if ($(v).isOnScreen(offset)){
        $(v).addClass('animation-in')
        $(v).css('transition-delay', delay+'s')
        if (splitText){
          let preambuleText = new SplitText(".j-textAnimation", {type:"lines"});
          let preambuleLines = preambuleText.lines;
            for(var i = 0; i<preambuleLines.length; i++){
                preambuleLines[i].innerHTML = '<span>'+preambuleLines[i].innerHTML+'</span>';
              }
            let tlPreambule = new TimelineLite({ paused: true});
            tlPreambule.staggerFrom($(preambuleLines).find('span'), 1.2, {y:"0%", ease: Power3.easeInOut},  0.05, "-=1.6");
        }
      }

      else{
        if (!($(v).hasClass('js-outOff')))
          $(v).removeClass('animation-in')
      }
    });
  }
}

