class HoverMenu {
  constructor(ref) {
  	let list = $(ref).siblings('.j-hoverMenu')

    $(ref).on('mouseenter', () => {
      $(ref).addClass('active')
      $(list).addClass('disabled')
      $(ref).removeClass('disabled')
    })
    $(ref).on('mouseleave', () => {
      $(ref).removeClass('active')
      $(list).removeClass('disabled')
    })
  }
}
