class Header {
  constructor(ref) {
    var header = ref;
    var w = $(window)
    let lastScrollTop = 0
    if (w.width() > 960){
      w.on('scroll', () => {
        var scrollmode;
        let w = $(window)
        let b = $('body')
        let header = $('.j-header')
        if (w.scrollTop() > lastScrollTop) {
          scrollmode = 'down';
        } else {
          scrollmode = 'up';
        }
        if (w.scrollTop() == 0) {
          header.removeClass('isNotVisible');
          header.removeClass('isVisible');
        }
        lastScrollTop = w.scrollTop();
        if (w.scrollTop() > 100) {
          header.addClass('isNotVisible');
        }
        if (scrollmode === 'up' && header.hasClass('isNotVisible')) {
          b.addClass('fixedHeader');
          return header.addClass('isVisible');
        }
        if (scrollmode === 'down' && header.hasClass('isNotVisible')) {
          b.removeClass('fixedHeader');
          header.removeClass('isVisible');
        }
      } )
    }

  }
}

