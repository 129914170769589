class ToggleMenu {
  constructor(ref) {
    $(ref).on('click', (e) => {
      e.preventDefault()
      let open = false
      let container = $(ref).closest('.j-openMenu')
      if ($(container).hasClass('openMenu')){
         open = true
      }
      // let list = $(container).siblings('.j-openMenu')
      // $(list).removeClass('open')
      if (open) {
        $(container).removeClass('openMenu')
      //   $('body').removeClass('noScroll')
    		// $('html').removeClass('noScroll')
      }

      else{
      	$(container).addClass('openMenu')
      // 	$('body').addClass('noScroll')
    		// $('html').addClass('noScroll')
      }
    })
  }
}
