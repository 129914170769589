class Scroll {
  constructor(ref) {
    $(ref).on('click', (e) => {
      e.preventDefault()
      let __ref = $(e.currentTarget);
      let _dest = $(__ref.attr('data-destination'));
      let target_top = Math.floor(_dest.offset().top);
      $('body').stop().animate({
        scrollTop: "" + target_top
      }, {
        duration: 500,
        easing: 'linear'
      })
    })

  }

}
