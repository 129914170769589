class Carousel {
  constructor(ref) {
    let carousel = $(ref).find('.j-carousel')
    let slideNumS = $(carousel).attr('slidevisibles')
    let slideNumM = $(carousel).attr('slidevisiblem')
    let slideNumL = $(carousel).attr('slidevisiblel')
    let mousewheelControl = ($(carousel).attr('mousewheelControl')) ? true : false
    let changingEffect = ($(carousel).attr('fade')) ? 'fade' : 'slide'
    let btn_nxt = $(carousel).find('.swiper-button-next')
    let btn_prev = $(carousel).find('.swiper-button-prev')
    let w = $(window)
    var mySwiper = new Swiper (carousel, {
        nextButton: btn_nxt,
        prevButton: btn_prev,
        speed: 500,
        simulateTouch: false,
        draggable: true,
        slidesPerView: slideNumL,
        effect: changingEffect,
        keyboardControl: true,
        slideClass: 'j-carouselSlide',
        grabCursor: true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        spaceBetween: 0,
        simulateTouch: true,
        breakpoints: {
          480: {
            slidesPerView: slideNumS,
            centeredSlides: true
          },
          640: {
            slidesPerView: slideNumM,
            centeredSlides: false,
            spaceBetween: 0
          },
          1024: {
            slidesPerView: slideNumL,
            centeredSlides: false,
            spaceBetween: 0
          }
        },
        onInit: function(){
          let slides = $(carousel).find('.j-carouselSlide')
          if (slides.length == 1) {
            $(carousel).find('.swiper-wrapper').css('transform', 'translate3d(0,0,0')
          };
      }
    })
  }
 }
