document.addEventListener("DOMContentLoaded",function(){

  window.is_mobile = ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Android/i)) ) ? true : false
  window.is_smartphone = ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/Android/i)) ) ? true : false
  if( window.is_mobile)
  $('body').addClass('deviceMobile')

  if( window.is_smartphone)
  $('body').addClass('isPhone')

  Array.from(document.getElementsByClassName('j-carouselCont')).forEach(v => {
    new Carousel(v);
  });

  var header = document.getElementsByClassName('j-header');
  if (header.length > 0) {
    new Header((header[0]));
  }

  Array.from(document.getElementsByClassName('j-openOverlay')).forEach(v => {
    new Overlay(v);
  });

  Array.from(document.getElementsByClassName('j-parallax')).forEach(v => {
    new Parallax(v);
  });
  // Array.from(document.getElementsByClassName('j-scrollButton')).forEach(v => {
  //     new Scroll(v);
  // });
  Array.from(document.getElementsByClassName('js-animation')).forEach(v => {
    new AnimationInOut(v);
  });
  // Array.from(document.getElementsByClassName('j-textAnimation')).forEach(v => {
  //   new TextAnimation(v);
  // });
  Array.from(document.getElementsByClassName('j-openToggle')).forEach(v => {
    new Toggle(v);
  });
  Array.from(document.getElementsByClassName('j-openToggleMenu')).forEach(v => {
    new ToggleMenu(v);
  });
  Array.from(document.getElementsByClassName('j-hoverMenu')).forEach(v => {
    new HoverMenu(v);
  });
  Array.from(document.getElementsByClassName('img-wrapper')).forEach(v => {
    new NoooImgWrapper(v);
  });

  window.initMap = function(){

    if(!window.globalContext.mapsReady.googleApi || !window.globalContext.mapsReady.initializer){
      return;
    }

    var markerPath = null;

    if( $('.ie').length || $('.microsoft').lenght ){

      markerPath = '/dist/images/marker/techmo-marker.png'

    }else{

      markerPath = '/dist/vectors/techmo-marker.svg'; 

    }

    var mapEl = document.getElementById('map');
    var mapOptions = {
      zoom: 4,
      disableDefaultUI: true,
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      center: {lat: 40, lng: 11},
      markersOptions : {
        url: window.globalContext.themePath+markerPath,
        size: new google.maps.Size(80, 80),
        scaledSize: new google.maps.Size(80, 80)  // makes SVG icons work in IE
      },
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "stylers": [
            {
              "color": "#a0d6ff"
            },
            {
              "saturation": 70
            },
            {
              "weight": 8
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#316d4f"
            },
            {
              "weight": 8
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ]
    };

    if($('body').hasClass('page-template-contacts')){
      mapOptions =  {
        zoom: 2,
        disableDefaultUI: true,
        // draggable: false,
        zoomControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        center: {lat: 40, lng: 11},
        markersOptions : {
          url: window.globalContext.themePath+markerPath,
          size: new google.maps.Size(40, 40),
          scaledSize: new google.maps.Size(40, 40)  // makes SVG icons work in IE
        },
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#afafaf"
              }
            ]
          },
          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "stylers": [
              {
                "color": "#a0d6ff"
              },
              {
                "saturation": 70
              },
              {
                "weight": 8
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#ffffff"
              },
              {
                "weight": 8
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      };

      /**
      *
      * BRANCHES LISTS GEOCODING
      *
      */

      if($('#branches-list').length){
        $('#branches-list li').each(function(){
          var lat = $(this).data('lat');
          var lng = $(this).data('lng');
          var position = new google.maps.LatLng(lat, lng);
          var geocoder = new google.maps.Geocoder;
          geocoder.geocode({ 'location': position}, function(results, status) {
            if (status == 'OK') {
              var addressComponents = results[0].address_components;
              var country = addressComponents[6].long_name;
              console.log(country);
            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          })
        });
      }
    }

    var map = new NoooMap( mapEl, mapOptions);
  };

  window.globalContext.mapsReady.initializer = true;
  initMap();

  /**
  *
  * NOOO FILTERS
  *
  */

  var filters = document.getElementById('nooo-filters');

  if( filters ){
    var fController = new NoooFilters(filters);

    $('.nooo-filter').click( function(e){
      fController.filterContent(e.target);
    });

    // ACTIVATE FILTER IF THERE IS A VALID HASH IN URL
    let anchorOnLoad = window.location.hash.substr(1);

    if(anchorOnLoad !== undefined || anchorOnLoad !== '' || anchorOnLoad !== null ){
      var filterOnLoad = fController.findActiveAnchor(anchorOnLoad);
      filterOnLoad.click();
    }
  }

  /**
  *
  * Lightgallery Initialization
  *
  */

  if( $('.prGallery').length ){
    $('.prGallery').lightGallery();
  }

  /**
  * Scroll Anchor Animation
  */

  var headersHeight = $('.header').height();

  $('a[href^="#"].roll').on('click',function (e) {
    e.preventDefault();
    var target = this.hash,
    $target = $(target);

    $('html, body').stop().animate({
      'scrollTop': $target.offset().top
    }, 800);
  });


  /**
  *
  * Preloader
  *
  */

  var $body = $('body');

  $('html').imagesLoaded(function(){

    $body.css({
      'overflow': 'inherit',
    });

    $body.removeClass('page-loading');

    if( $('.home').lenght ){

      $('.hpIntro__image video')[0].play();

    }


    // DISPLAY NONE OF LOADER FOR SEO
    window.setTimeout(function(){
      $('#preloader').addClass('killed');
    }, 1500);
  });

  /**
  *
  * AJAX LOAD
  *
  */
  
  /*function countNews(){
  return $('[data-nooo-filters]').length;
}

$('.load-more').click(function( event ) {
event.preventDefault();

var start = countNews();

$.ajax({
url: ajaxapp.ajaxurl,
type: 'post',
data: {
action: 'ajax_app',
query_vars: ajaxapp.query_vars,
start:  start
},
success: function( html ) {
$('#news-list').append(html);
}
})
});
*/
});
