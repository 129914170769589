class Parallax {
  constructor(ref) {
    var fig = ref;
    let speed = $(ref).attr('data-speed')
    let item = $(ref).find('.j-parallaxItem')
    let w = $(window)
    if (w.width() >= 768){
      w.on('scroll', () => this.scrolling(item, w, fig, speed) )
    }


  }
  scrolling(ref, w, fig, speed){

    TweenLite.set($(ref), {y: '0%'});
    var animationPrllx = TweenLite.to($(ref), 1, { yPercent: -speed, ease: Linear.easeNone, paused: true});

    let wH = $(window).height();
    if ($(fig).offset().top  < 100){
     var minCONTENT = $(fig).offset().top - wH/5 ;
    }
    else{
     var minCONTENT = $(fig).offset().top - wH ;

    }
    var max = $(fig).offset().top + $(fig).outerHeight();
    var normCONTENT = this.clamp(this.normalize(window.pageYOffset, minCONTENT , max), 0, 1);
    animationPrllx.progress(normCONTENT);
  }
  clamp(value, min, max) {
    return value < min ? min : (value > max ? max : value);
  }
  normalize(value, min, max) {
    return (value - min) / (max - min);
  }


}

