class NoooFilters {

    constructor( ref, speed ){
        this.$filters = $(ref).find('.nooo-filter');
        this.dataTarget = 'data-nooo-filters';
        this.dataTargetSel = '[data-nooo-filters]';
        this.animationDuration = ( speed !== undefined ? speed : 400 );
        this.timeline = '';
    }

    getAnchor (el){
        let $el = $(el);

        let href = $el.attr('href');
        return href.replace('#', '');

    }

    findActiveAnchor(clickedAnchor){
        let that = this;
        let $match = false;

        this.$filters.each( function() {
            if( clickedAnchor == that.getAnchor(this)){
                $match = $(this);
            }
        });

        // CASE NO FILTER IS VALID RESET FILTERS
        if($match.length){
            return $match;
        } else {
            return $(that.dataTargetSel);
        }
    }

    pickSelected ( filter ) {
        let that = this;
        let $targets = $(this.dataTargetSel);
        let matches = [];

        $targets.each(function () {
            let cats = $(this).attr(that.dataTarget);

            if (cats.indexOf(filter) != -1) {
                matches.push(this);
            }
        });

        // IF NO FILTER IS VALID ADD CLASS TO SHOW ALL
        if (matches.length) {
            return $(matches);
        }
    }

    filterOut(){
        this.$filters.removeClass('active');

        $(this.dataTargetSel).css({
            'display' : 'none'
        });

    }

    filterIn($filter, $selection){
        $filter.addClass('active');

        // CASE SELECTION IS UNSUCCESFULL
        if( $selection !== undefined ){
            $selection.css({
                'display' : 'block'
            });
        }else{
            $(this.dataTargetSel).css({
                'display' : 'block',
            });
        }
    }


    animateFiltering ($filter, $selection){
        let that = this;
        if( this.timeline !== '' ){
            this.timeline.pause();
        }

        var newTimeline = anime.timeline();
        this.timeline = newTimeline;


        newTimeline
            .add({
                targets:    that.dataTargetSel,
                opacity:    [1, 0],
                scale:      [1, .85],
                duration:   that.animationDuration,
                easing:     'easeOutQuad',
                begin:      function(){
                    $(that.dataTargetSel).addClass('animating');
                },
                complete:   function(){

                    that.filterOut();
                    that.filterIn($filter, $selection);
                }
            })
            .add({
                targets:    that.dataTargetSel,
                opacity:    [0, 1],
                scale:      [1.15, 1],
                duration:   that.animationDuration * 1.25,
                easing:     'easeOutQuad',
                complete:   function(){
                    $(that.dataTargetSel).removeClass('animating');
                }
            })
    }


    filterContent (filter){
        let $filter = $(filter);

        // STOP EXECUTION IF USER IS CLICKING ON ACTIVE FILTER
        if( $filter.hasClass('active') ){
            return;
        }

        // RESET
        if($filter.hasClass('nooo-filter-reset')){
            this.animateFiltering($filter);
            return;

        } else { //FILTER
            let needle = this.getAnchor(filter);
            let $selection = this.pickSelected(needle);
            this.animateFiltering( $filter, $selection);
        }
    }
}