class NoooMap {

    constructor(noooMap, options) {
        this.$map = $(noooMap);
        this.options = options;

        return this.renderMaps( this.$map )
    }

    /**
     *  new_map
     *
     *  This function will render a Google Map onto the selected jQuery element
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$el (jQuery element)
     *  @return	n/a
     */

    new_map( $el, options ) {

        let that = this;

        // var
        var $markers = $el.find('.marker');


        if( options === undefined ){
            // Default options
            var options = {
                zoom		: 16,
                center		: new google.maps.LatLng(0, 0),
                mapTypeId	: google.maps.MapTypeId.ROADMAP
            };
        }


        // create map
        var map = new google.maps.Map( $el[0], options);


        // add a markers reference
        map.markers = [];

        // add markers
        $markers.each(function(){

            that.add_marker( $(this), map, that.options );

        });


        // center map
        this.center_map( map );


        // return
        return map;

    }

    /**
     **  add_marker
     *
     *  This function will add a marker to the selected Google Map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	$marker (jQuery element)
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    add_marker( $marker, map , options) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
        var icon = '';

        if(options.markersOptions){
            icon = options.markersOptions;
        }

        // create marker
        var marker = new google.maps.Marker({
            position	: latlng,
            map			: map,
            icon        : icon,
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new window.google.maps.InfoWindow({
                content		: $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {

                infowindow.open( map, marker );

            });
        }

    }

    /**
     *  center_map
     *
     *  This function will center the map, showing all markers attached to this map
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	4.3.0
     *
     *  @param	map (Google Map object)
     *  @return	n/a
     */

    center_map( map ) {

        let zoom = ( this.options.zoom !== undefined ? this.options.zoom : 16 );

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( zoom );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }

    }

    /**
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type	function
     *  @date	8/11/2013
     *  @since	5.0.0
     *
     *  @param	n/a
     *  @return	n/a
     */

    renderMaps(map){
        let that = this;

        map.each(function(){

            // create map
            map = that.new_map( $(this), that.options );

        });
    }
}